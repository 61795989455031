import { getEmailPreviewFromHtmlString } from "utils/htmlParser";
import {
  getSchedulePreview,
  getTimeBasedCategoryType,
} from "../EmailDripFlow/EmailDripFlow.utils";
import { EMAIL_BUILDER_TYPE_ADVANCE } from "features/EmailBuilder/modules/EmailBodyEditor/constants/EmailBodyEditor.constants";
import { useEffect, useState } from "react";
import { fetchHTMLString } from "features/EmailBuilder/modules/EmailBodyEditor/utils/projectData&HTML";

export const emailDetailApiKeys = {
  body: "email_body",
  subject: "email_subject",
};

const EmailBody = ({ email_type, email_body, meta_info, template_link }) => {
  const [emailBody, setEmailBody] = useState();
  useEffect(() => {
    if (email_type === EMAIL_BUILDER_TYPE_ADVANCE) {
      fetchHTMLString(template_link).then((i) => setEmailBody(i));
    } else {
      setEmailBody(email_body);
    }
  }, []);

  return getEmailPreviewFromHtmlString(emailBody || "", meta_info);
};

export const getFieldConfig = ({ dripDetails }) => {
  const timeBasedCategoryType = getTimeBasedCategoryType(dripDetails);

  return [
    {
      label: "Message",
      format: EmailBody,
      className: "line_max_1",
      contentClassName: "line_max_1",
    },
    {
      label: "Scheduled for",
      format: (data) => getSchedulePreview({ ...data, timeBasedCategoryType }),
    },
  ];
};
